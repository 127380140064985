import React from 'react'
import { Button, Container } from 'reactstrap'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../layouts'
import Accueil from '../components/accueil.md'
import CallToAction from '../components/call-to-action'
import Planning from '../../static/images/accueil/planning-2019-2020.svg'

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "accueil/academie-martiale-nice.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout
      title="Accueil"
      location="/">
      <header className="d-flex " style={{
        height: '100vh',
        backgroundColor: '#1c1c1c',
        backgroundImage: `url("${data.heroImage.childImageSharp.fluid.src}")`,
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }}>
        <Container className="d-none d-xl-block"></Container>
        <div className="d-xl-none" style={{backgroundColor: 'rgba(25,25,25,0.5)', position: 'absolute', width: '100%', height: '100%' }} />
        <Container fluid className="justify-items-left d-flex flex-column text-center my-auto masthead-mobile" style={{padding: '1rem 1rem'}}>
          <h1 className="mb-5" style={{ color: '#eaeaea' }}>
            La saison 2019-2020 commence&nbsp;!
          </h1>
          <h3 className="mb-1 masthead-mobile" style={{ color: '#bababa' }}>
            490 € à l’année, payable en plusieurs fois
          </h3>
          <h3 className="mb-1 masthead-mobile" style={{ color: '#bababa' }}>
            Accès à toutes les disciplines externes
          </h3>
          <h3 className="mb-5 masthead-mobile" style={{ color: '#bababa' }}>
            License et cotisation comprises
          </h3>
          <Link to="/contact">
            <Button size="lg">Contactez-nous</Button>
          </Link>
        </Container>
      </header>
      <main>
        <Container
          fluid
          style={{
            backgroundColor: '#1e1e1e',
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <Container
            style={{
              paddingTop: '3rem',
              paddingBottom: '3rem',
              backgroundColor: '#f8f8f8',
            }}
          >
            <h1>
              Académie Martiale des 9 Dragons
            </h1>
            <div style={{
              textAlign: 'center',
              paddingBottom: '2rem'
            }}>
              Bienvenue sur le site officiel de l&rsquo;école de Nice.
            </div>
            <div className="embed-responsive embed-responsive-16by9" style={{ paddingBottom: '2rem'}}>
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/1BpBu8cM2Vw"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="embed-responsive-item" />
            </div>
            <div className="container" style={{ paddingTop: '2rem'}}>
              <Accueil />
            </div>
            <div className="container-fluid" style={{ paddingTop: '2rem' }}>
              <a href={Planning} rel='noreferrer noopener' target='_blank'>
                <img src={Planning} alt='Planning de la saison 2019-2020' width='100%' />
              </a>
            </div>
          </Container>
          <CallToAction />
        </Container>
      </main>
    </Layout>
  )
}

export default Index
