import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`L’école d’arts martiaux des 9 dragons existe en France depuis presque 40 ans.`}</p>
    <p>{`Près de 500 élèves pratiquent ce style d’arts martiaux, chaque année, sur
l’ensemble des 9 clubs français.`}</p>
    <p>{`Le club de Nice est sous la responsabilité de M. Tran David, fils du
Maître-fondateur du style en France, M. Tran Hoai-Ngoc.`}</p>
    <p><strong parentName="p">{`L’académie martiale des 9 dragons de Nice`}</strong>{` vous propose pour une seule et même
inscription :`}</p>
    <ul>
      <li parentName="ul">{`du `}<strong parentName="li">{`kung-fu traditionnel sino-vietnamien`}</strong>{` (Vo-Co-Truyen), qui s’apparente
à des styles comme le `}<strong parentName="li">{`Choy Lay Fut`}</strong>{`, le `}<strong parentName="li">{`Hung Gar`}</strong>{` et même le
`}<strong parentName="li">{`Wing-Chun`}</strong>{` (Vinh Xuân) ;`}</li>
      <li parentName="ul">{`des mouvements uniquement basés sur des techniques visuellement
esthétiques, pour la section `}<strong parentName="li">{`art martial artistique`}</strong>{` que l’on appelle
aussi Tricking ;`}</li>
      <li parentName="ul">{`de la self-défense, avec la méthode du `}<strong parentName="li">{`Jeet-Kune-Do`}</strong>{`, l’art martial créé
par Bruce Lee, embelli par Dan Inosanto, transmis par Ron Balicki ;`}</li>
      <li parentName="ul">{`du combat libre `}<strong parentName="li">{`Free-Fight`}</strong>{` mais avec des règles spécifiques qui
limitent les accidents, la section arts martiaux mixtes permet une approche
multi-distance en combat : boxe pied-poing, lutte, grappling. On utilise des
techniques venant de la boxe thaïlandaise, du tae-kwon-do, du kenpo, du judo
et du jiu-jitsu brésilien.`}</li>
      <li parentName="ul">{`la pratique des `}<strong parentName="li">{`armes courtes`}</strong>{`, comme le bâton court, les couteaux mais
qui a aussi une méthode de boxe très spécifique, avec un travail
d’automatisme pour avoir les bons réflexes d’attaque et de défense, le
`}<strong parentName="li">{`Kali-Eskrima`}</strong>{` est une mine de technique qui a été la source de certains
styles de self-défense très prisé dans le monde.`}</li>
      <li parentName="ul">{`un aspect totalement geek… qui n’a jamais rêvé de manier un `}<strong parentName="li">{`sabre-laser`}</strong>{`
comme dans les films de Star Wars? Sur de vraies bases martiales, enseigné
par des professeur d’arts martiaux, quoi de mieux ?`}</li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      